import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './PersonalInfo.scss'
import { useEffect } from 'react'
import { toInfoClass, toInfoPrice, toInfoSchool } from '../content/ContentPage'

export function PersonalInfo() {
  useEffect(() => {
    window.scrollTo({
      top: -10000,
    })
  }, [])
  const pushTo = useNavigate()
  function logoToUp() {
    window.scrollTo({
      top: -10000,
      behavior: 'smooth',
    })
  }

  function toMainPageSchool() {
    pushTo('/');
    setTimeout(() => {
        toInfoSchool()
    }, 600);
  }
  function toMainPageLesson() {
    pushTo('/')
    setTimeout(() => {
        toInfoClass()
    }, 600);
  }
  function toMainPagePrice() {
    pushTo('/')
    setTimeout(() => {
        toInfoPrice()
    }, 600);
  }
  function toMainPageContact() {
    pushTo('/')
    setTimeout(() => {
            window.scrollTo({
              top: 10000,
              left: 0,
              behavior: 'smooth',
            })
    }, 300);
  }

  return (
    <body>
      <div className="content-main">
        <header className="content-main_header">
          <Link to="/">
            <div className="content-main_logoHeader"></div>
          </Link>
          <div className="content-main_header__list">
            <Link to="/">
              <div className="header__list-buttonHelper"></div>
            </Link>
            <button onClick={toMainPageSchool} className="header__list-button">
              О школе
            </button>
            <button onClick={toMainPageLesson} className="header__list-button">
              О занятиях
            </button>
            <button onClick={toMainPagePrice} className="header__list-button">
              Цены
            </button>
            <Link to="/review">
              <button className="header__list-button">Отзывы</button>
            </Link>
            <button onClick={toMainPageContact} className="header__list-button">
              Контакты
            </button>
            <Link to="https://wa.me/message/QWOKY3XT4HUHA1">
              <button className="header__list-button2">ЗАПИСАТЬСЯ</button>
            </Link>
          </div>
        </header>
        <div className="personalInfo-main_offer">
          <h1 className="personalInfo-main_offer__h1">
            ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ
          </h1>
          <h2 className="personalInfo-main_offer__h2">
            Настоящий договор является официальным предложением, адресованным
            любому физическому лицу, которое соглашается с условиями настоящей
            публичной оферты путем ее полного и безоговорочного акцепта, в
            дальнейшем именуемому «Заказчик». Заказчик обязан полностью
            ознакомиться с настоящим документом до момента внесения оплаты по
            договору. Лазарева Мария Валерьевна, зарегистрированная 13.10.2023
            г. в качестве индивидуального предпринимателя (ОГРНИП
            323595800122942, ИНН 590773912037, юридический адрес: 614030,
            Пермский край, г. Пермь, ул. Пулковская, д. 5 кв. 25), именуемая в
            дальнейшем «Исполнитель», выражает намерение заключить договор об
            оказании платных образовательных услуг с Заказчиком на условиях
            настоящей оферты, далее — Договор.
            <br /> <br /> 1. ОПРЕДЕЛЕНИЯ <br /> 1.1. Сайт — интернет-ресурс,
            содержащий полную информацию о cодержании и стоимости
            образовательных услуг (уроков английского языка), размещенный по
            адресу https://easysqueezy.ru/. <br /> 1.2. Оферта — настоящий
            документ, опубликованный на сайте https://easysqueezy.ru/. <br />{' '}
            1.3. Акцепт Оферты — полное и безоговорочное принятие Оферты путем
            осуществления действий, указанных в пункте 2.2 Оферты. Акцепт Оферты
            создает Договор. <br /> 1.4. Заказчик — совершеннолетнее лицо,
            осуществившее Акцепт Оферты, являющееся потребителем платных
            образовательных услуг по заключенному Договору, либо правомочным
            представителем несовершеннолетнего лица, которое будет потребителем
            платных образовательных услуг. <br /> 1.5. Договор — настоящий
            договор между Заказчиком и Исполнителем на предоставление
            образовательных услуг, который заключается посредством Акцепта
            Оферты.
            <br /> 1.6. Пакет услуг — систематизированный объем услуг
            (количество и продолжительность уроков) и условия их оказания, а
            также стоимость; пакеты услуг доступны для ознакомления и выбора на
            сайте Исполнителя https://easysqueezy.ru/. <br /> 1.7. Цифровая
            платформа – лингвистическая платформе https://progressme.ru/,
            посредством доступа к которой обеспечивается проведение занятий.{' '}
            <br /> 1.8. Занятие – время, в течение которого Исполнителем
            проводится урок с Заказчиком. Уроки проводятся в соответствии с
            программой обучения, определяемой Исполнителем с учетом уровня
            знаний и целей обучения Заказчика. <br /> <br /> 2. ПРЕДМЕТ ДОГОВОРА
            2.1. Исполнитель предоставляет, а Заказчик оплачивает
            образовательные услуги в рамках выбранного пакета услуг, условия
            которых определены приложением к Договору. Приложение размещено в
            соответствующем разделе Сайта. <br /> 2.2. Оплатой Заказчик выражает
            свое полное и безоговорочное принятие условий Договора. <br /> 2.3.
            Стоимость услуг по Договору для конкретного пакета услуг указана на
            Сайте. <br /> <br /> 3. ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ Исполнитель обязан:{' '}
            <br /> 3.1. Организовывать и обеспечивать надлежащее исполнение
            услуг, предусмотренных в пункте 2.1 настоящего Договора, в
            соответствии с пакетом услуг, выбранным Заказчиком, в том числе:
            выстроить план обучения Заказчика, проводить уроки, проверять
            домашние задания, давать обратную связь. <br /> 3.2.
            Проводить регистрацию Заказчика на цифровой платформе для
            организации доступа к учебным материалам и урокам, предоставлять
            логин и пароль к личному кабинету.
            <br />
            3.3. Обеспечивать Заказчика всеми необходимыми учебными
            методическими материалами согласно программе курса. <br /> 3.4.
            Перенести занятие либо предоставить альтернативную онлайн площадку
            для проведения занятия в случае отсутствия доступа к учебным
            материалам в связи с техническими неполадками цифровой платформы.{' '}
            <br /> 3.5. Отвечать за действия третьих лиц, привлекаемых к
            процессу оказания услуг по Договору.
            <br /> <br /> 4. ОБЯЗАННОСТИ ЗАКАЗЧИКА Заказчик обязан: <br />{' '}
            4.1. Предоставить Исполнителю все сведения и данные, необходимые для
            выполнения своих обязательств по заключенному Договору. <br /> 4.2.
            Выполнять задания по подготовке к занятиям. <br /> 4.3. Следовать
            инструкциям Исполнителя при выполнении заданий. <br /> 4.4.
            Информировать Исполнителя в случае, если материал курса не понятен
            или не усвоен Заказчиком. <br /> 4.5. Сдавать Исполнителю
            выполненные домашние задания до начала занятия, следующего за
            занятием, на котором он получил задание. <br /> 4.6. Соблюдать
            авторские права Исполнителя на все материалы курса в соответствии с
            гражданским законодательством. <br />
            <br /> 5. ПРАВА ИСПОЛНИТЕЛЯ И ЗАКАЗЧИКА Исполнитель вправе: <br />{' '}
            5.1. Привлечь третьих лиц для оказания услуг. Заказчик вправе:{' '}
            <br /> 5.2. Требовать от Исполнителя предоставления информации по
            вопросам, касающимся организации и обеспечения надлежащего
            исполнения услуг, предусмотренных разделом 2 Договора. <br />
            <br /> 6. ОПЛАТА УСЛУГ <br /> 6.1. Заказчик оплачивает услуги,
            указанные в разделе 2.1 Договора в российских рублях. Оплата
            предоставляемых услуг производится на основании счета на оплату,
            либо с применением платежной системы посредством заполнения формы,
            размещенной на Сайте. <br /> 6.2. Заказчик производит оплату
            услуг Исполнителя до начала обучения в размере 100 процентов
            предоплаты путем перечисления безналичных денежных средств на
            расчетный счет Исполнителя, указанный в Договоре. <br /> 6.3.
            Моментом оплаты считается поступление средств на расчетный
            счет Исполнителя. <br />
            <br /> 7. ПОРЯДОК ПОЛУЧЕНИЯ УСЛУГ <br /> 7.1. Исполнитель
            предоставляет Заказчику необходимую информацию для занятий онлайн, в
            том числе логин и пароль от личного кабинета на цифровой платформе;
            согласовывает график проведения занятий с Заказчиком. <br /> 7.2.
            Заказчик может отменить или перенести занятие не позднее, чем за 4
            часа до ранее согласованного времени проведения занятия. Для
            переноса/ отмены занятия Заказчик обязан связаться с Исполнителем по
            телефону, указанному в реквизитах Исполнителя в тексте настоящей
            оферты или по телефону, указанному на сайте https://easysqueezy.ru/.
            В случае несоблюдения данного условия занятие считается состоявшимся
            и оплачивается Заказчиком в размере 100%. <br /> 7.3. Занятия,
            которые не состоялись по вине Исполнителя, переносятся без их потери
            на другое время по согласованию с Заказчиком. При переносе занятия
            по вине Исполнителя Заказчику предоставляется одно бонусное
            (дополнительное) занятие сверх оплаченного пакета услуг. <br />
            <br /> 8. СОБЛЮДЕНИЕ АВТОРСКОГО ПРАВА И КОНФИДЕНЦИАЛЬНОСТЬ
            <br />
            8.1. Заказчику запрещается копировать какие-либо видео- или
            текстовые материалы. 
            <br /> 8.2. Заказчику запрещено использовать полученные на занятиях
            учебные материалы в коммерческих целях.
            <br /> 8.3. Заказчику запрещено использовать полученные на занятиях
            учебные материалы от своего имени, размещать в открытом доступе,
            передавать третьим лицам.
            <br />
            8.4. Заказчику запрещено передавать третьим лицам логин и пароль
            доступа к цифровой платформе. Обнаружение факта доступа к материалам
            курсов третьих лиц является основанием для одностороннего
            расторжения Исполнителем Договора без возврата денежных средств.{' '}
            <br />
            <br /> 9. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ <br /> 9.1. Персональные
            данные Заказчика обрабатываются в соответствии с Федеральным законом
            № 152-ФЗ «О персональных данных».
            <br /> 9.2. При регистрации на сайте и цифровой
            платформе Заказчик предоставляет следующую информацию: фамилия, имя,
            отчество, контактный номер телефона, адрес электронной почты.
            <br /> 9.3. Предоставляя свои персональные
            данные Исполнителю, Заказчик соглашается на их обработку
            Исполнителем, в том числе в целях выполнения Исполнителем
            обязательств перед Заказчиком в рамках настоящей публичной оферты,
            проведения электронных информационных рассылок, контроля результатов
            маркетинговых акций, клиентской поддержки, проведение розыгрышей
            призов, контроля качества услуг, оказываемых Исполнителем.
            <br />
            9.4. Под обработкой персональных данных понимается любое
            действие или совокупность действий, совершаемых Исполнителем с
            использованием средств автоматизации или без использования таких
            средств с персональными данными, включая сбор, запись,
            систематизацию, накопление, хранение, уточнение (обновление,
            изменение) извлечение, использование, обезличивание, блокирование,
            удаление, уничтожение персональных данных.
            <br />
            9.5. Исполнитель вправе использовать технологию cookies. Cookies не
            содержат конфиденциальную информацию. Заказчик настоящим дает
            согласие на сбор, анализ и использование cookies, в том числе
            третьими лицами для целей формирования статистики и оптимизации
            рекламных сообщений.
            <br />
            9.6. Исполнитель получает информацию об IP-адресе посетителя Сайта.{' '}
            <br />
            <br /> 10. ОСНОВАНИЯ И УСЛОВИЯ ИЗМЕНЕНИЯ И РАСТОРЖЕНИЯ ДОГОВОРА{' '}
            <br />
            10.1. Условия, на которых заключен Договор, могут быть изменены либо
            по соглашению сторон, либо в соответствии с действующим
            законодательством Российской Федерации. <br />
            10.2. Договор может быть расторгнут по соглашению сторон.
            <br />
            10.3. Договор по инициативе одной из сторон Договор может быть
            расторгнут по основаниям, предусмотренным действующим
            законодательством Российской Федерации. <br />
            10.4. По требованию Заказчика плата за неиспользованные уроки,
            входящие в состав оплаченного пакета услуг, возвращается
            Исполнителем с учетом положений пункта 7.2. Договора. Возврат
            осуществляется в течение десяти рабочих дней с момента расторжения
            Договора. <br />
            10.5. Договор считается расторгнутым со дня письменного
            уведомления Исполнителем Заказчика об отказе от исполнения Договора.
            <br />
            <br /> 11. ОТВЕТСТВЕННОСТЬ СТОРОН
            <br /> 11.1. В случае неисполнения или ненадлежащего исполнения
            сторонами обязательств по Договору они несут ответственность,
            предусмотренную гражданским законодательством и законодательством о
            защите прав потребителей, на условиях, установленных этим
            законодательством. <br />
            11.2. Заказчик соглашается и признает, что действия, совершенные с
            использованием логина и пароля в личном кабинете, порождают
            юридические последствия, аналогичные использованию личных подписей.{' '}
            <br />
            <br />
            12. СРОК ДЕЙСТВИЯ ДОГОВОРА
            <br /> 12.1. Настоящий Договор вступает в силу с момента
            акцепта Заказчиком данной Публичной оферты и действует до полного
            исполнения Сторонами взятых на себя обязательств.
            <br />
            12.2. Исполнитель оставляет за собой право внести изменения в
            настоящий Договор в любой момент по своему усмотрению, такие
            изменения вступают в силу с момента размещения измененного текста
            Договора, если иной срок вступления изменений в силу не определен
            дополнительно в тексте публикации. <br />
            <br />
            Исполнитель: Индивидуальный предприниматель Лазарева Мария
            Валерьевна <br /> ИНН: 590773912037 ОГРНИП: 323595800122942 <br />
            Адрес: 614030, Пермский край, г. Пермь, ул. Пулковская, д. 5 кв. 25{' '}
            <br /> Банковские реквизиты: Номер счёта: 40802810329190009040{' '}
            <br /> Валюта: РОССИЙСКИЙ РУБЛЬ Банк: ФИЛИАЛ «НИЖЕГОРОДСКИЙ» АО
            «АЛЬФА-БАНК» <br />
            Корреспондентский счёт: 30101810200000000824 БИК: 042202824
          </h2>
        </div>
        <div className="content-main_footer">
          <div className="content-main_footer__connect">
            <h1 className="footer__connect-h1">ПРИСОЕДИНЯЙТЕСЬ!</h1>
            <p className="footer__connect-p">
              вас ждут удивительные английские приключения!
            </p>
            <Link to="https://wa.me/message/QWOKY3XT4HUHA1">
              <button className="footer__connect-button">ЗАПИСАТЬСЯ</button>
            </Link>
          </div>
          <div className="content-main_footer__feedback">
            <div className="help-footer_block">
              <h1 className="footer__feedback-h1">КАК С НАМИ СВЯЗАТЬСЯ?</h1>
              <div className="content-main_footer__blockFirstHelp">
                <div className="footer__feedback-blockFirst">
                  <div className="footer-feedback_gap">
                    <a
                      className="feedback-blockFirst_email"
                      href="mailto:easysqueezyschool@yandex.ru"
                    >
                      easysqueezyschool@yandex.ru
                    </a>
                    <a
                      className="feedback-blockFirst_email"
                      href="tel:+79655678265"
                    >
                      +7 (965) 56-78-265
                    </a>
                  </div>
                  <div className="feedback-blockFirst-line"></div>
                  <div className="feedback-blockFirst_img">
                    <Link to="https://vk.com/id38479372">
                      <img
                        className="blockFirst_img-feedback"
                        src="/img/vk.png"
                        alt="vk"
                      />
                    </Link>
                    <Link to="https://t.me/mashiklaz">
                      <img
                        className="blockFirst_img-feedback"
                        src="/img/tg.png"
                        alt="tg"
                      />
                    </Link>
                    <Link className="outline-none" to="/offer">
                      <p className="footer-contractOffer pointer">
                        Договор оферта
                      </p>
                    </Link>
                    <p className="footer-contractOffer">
                      © 2024 Easy Squeezy ИП Лазарева Мария Валерьевна <br />{' '}
                      ИНН: 590773912037 ОГРНИП: 323595800122942
                    </p>
                  </div>
                </div>
                <img
                  onClick={logoToUp}
                  className="hiddenImg"
                  src="img/mobile/logoFooterMobile.png"
                  alt=""
                />
              </div>
            </div>
            <img
              onClick={logoToUp}
              className="content-main_footerLogo"
              src="/img/logoFooter.png"
              alt="logoFooter"
            />
          </div>
          <p className="footer-contractOfferMobile footer-contractOfferMargin">
            {' '}
            © 2024 Easy Squeezy ИП Лазарева Мария Валерьевна <br />
            ИНН: 590773912037 ОГРНИП: 323595800122942
          </p>
        </div>
      </div>
    </body>
  )
}
