import React from 'react'
import './ContentPage.scss'
import { Link } from 'react-router-dom'
import { useRef } from 'react'

let ref1Help;
let ref2Help;
let refHelp;

export function toInfoClass() {
  const helper = ref1Help.current.scrollIntoView({
    behavior: 'smooth',
  })
  setTimeout(() => {
    window.scrollTo({
      top: helper,
      left: 0,
      behavior: 'smooth',
    })
  }, 1000)
}


export function toInfoSchool() {
  const helper = refHelp.current.scrollIntoView({
    behavior: 'smooth',
  })
  setTimeout(() => {
    window.scrollTo({
      top: helper,
      behavior: 'smooth',
    })
  }, 1000)
}

export function toInfoPrice() {
  const helper = ref2Help.current.scrollIntoView({
    behavior: 'smooth',
  })
  setTimeout(() => {
    window.scrollTo({
      top: helper,
      left: 0,
      behavior: 'smooth',
    })
  }, 1000)
}

export function ContentPage() {
  const ref2 = useRef()
  const ref = useRef()
  const ref1 = useRef()
  ref1Help = ref1;
  ref2Help = ref2;
  refHelp = ref;
  
  function toInfoContact() {
    window.scrollTo({
      top: 10000,
      behavior: 'smooth',
    })
  }

  function logoToUp() {
    window.scrollTo({
      top: -10000,
      behavior: 'smooth',
    })
  }

  return (
    <body>
      <div className="content-main">
        <header className="content-main_header">
          <Link to="/">
            <div className="content-main_logoHeader"></div>
          </Link>
          <div className="content-main_header__list">
            <Link to="/">
              <div className="header__list-buttonHelper"></div>
            </Link>
            <button onClick={toInfoSchool} className="header__list-button">
              О школе
            </button>
            <button onClick={toInfoClass} className="header__list-button">
              О занятиях
            </button>
            <button onClick={toInfoPrice} className="header__list-button">
              Цены
            </button>
            <Link to="/review">
              <button className="header__list-button">Отзывы</button>
            </Link>
            <button onClick={toInfoContact} className="header__list-button">
              Контакты
            </button>
            <Link to="https://wa.me/message/QWOKY3XT4HUHA1">
              <button className="header__list-button2">ЗАПИСАТЬСЯ</button>
            </Link>
          </div>
        </header>
        <div className="content-main_info1">
          <div ref={ref} className="content-main_info1__first">
            <div className="first-help">
              <img src="/img/textInfo.png" alt="" />
              <img src="/img/LogoInfo.png" alt="" />
            </div>
          </div>
          <div className="content-main_info1__second">
            <div className="second-text">
              <p className="second-text__p">Индивидуальные уроки</p>
              <p className="second-text__p">Интерактивные занятия</p>
              <p className="second-text__p">Для детей от 4-х лет</p>
              <p className="second-text__p">Разговорный клуб</p>
              <p className="second-text__p">
                Подготовка к обучению в международной школе
              </p>
            </div>
          </div>
        </div>
        <div className="content-main_infoSchool">
          <img
            className="infoSchool-imgMobile"
            src="/img/mobile/InfoSchool2Block.png"
            alt=""
          />
          <img className="infoSchool-img" src="/img/infoSchool.png" alt="" />
        </div>
        <div ref={ref1} className="content-main_infoClasses">
          <h1 className="infoClasses-h1">О ЗАНЯТИЯХ</h1>
          <div className="infoClasses-mainInfo">
            <div className="infoClasses-mainInfo_block">
              <div className="block-help">
                <h2 className="mainInfo_block__h2">Для дошкольников</h2>
                <h2 className="mainInfo_block__p">
                  Мы предлагаем занятия в игровом формате с яркими иллюстрациями
                  и персонажами. Упор на развитие умения слышать и понимать
                  английскую речь, а также сделать первые шаги в говорении.
                </h2>
                <Link to="https://wa.me/message/QWOKY3XT4HUHA1">
                <button className="mainInfo_block__button">Попробовать</button>
                </Link>
              </div>
            </div>
            <div className="infoClasses-mainInfo_block">
              <div className="block-help">
                <h2 className="mainInfo_block__h2">
                  Для учеников начальной школы
                </h2>
                <h2 className="mainInfo_block__p">
                  Мы заложим ту самую необходимую базу слов и конструкций,
                  которые не только помогут ребенку понимать, говорить и читать
                  на английском, но и прекрасно справляться с этим предметов в
                  школе.
                </h2>
                <Link to="https://wa.me/message/QWOKY3XT4HUHA1">
                <button className="mainInfo_block__button">Попробовать</button>
                </Link>
              </div>
            </div>
            <div className="infoClasses-mainInfo_block">
              <div className="block-help">
                <h2 className="mainInfo_block__h2">
                  Для учеников средней и старшей школы
                </h2>
                <h2 className="mainInfo_block__p">
                  Мы поможем разобраться с грамматикой, наработать необходимый
                  словарный запаса и наконец-то заговорить. Уроки на актуальные
                  и интересные темы обязательно заинтересуют подростка.
                </h2>
                <Link to="https://wa.me/message/QWOKY3XT4HUHA1">
                <button className="mainInfo_block__button">Попробовать</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="content-main_question">
          <img
            className="content-main_question__imgMobile"
            src="/img/mobile/questionSchool.png"
            alt=""
          />
          <img
            className="content-main_question__img"
            src="/img/questionBlock.png"
            alt=""
          />
        </div>
        <div ref={ref2} className="content-main_price">
          <img
            className="content-main_price__imgMobile"
            src="img/mobile/priceBlock.png"
            alt=""
          />
          <img className="content-main_price__img" src="img/price.png" alt="" />
        </div>
        <div className="content-main_footer">
          <div className="content-main_footer__connect">
            <h1 className="footer__connect-h1">ПРИСОЕДИНЯЙТЕСЬ!</h1>
            <p className="footer__connect-p">
              вас ждут удивительные английские приключения!
            </p>
            <Link to="https://wa.me/message/QWOKY3XT4HUHA1">
              <button className="footer__connect-button">ЗАПИСАТЬСЯ</button>
            </Link>
          </div>
          <div className="content-main_footer__feedback">
            <div className="help-footer_block">
              <h1 className="footer__feedback-h1">КАК С НАМИ СВЯЗАТЬСЯ?</h1>
              <div className="content-main_footer__blockFirstHelp">
                <div className="footer__feedback-blockFirst">
                  <div className="footer-feedback_gap">
                    <a
                      className="feedback-blockFirst_email"
                      href="mailto:easysqueezyschool@yandex.ru"
                    >
                      easysqueezyschool@yandex.ru
                    </a>
                    <a
                      className="feedback-blockFirst_email"
                      href="tel:+79655678265"
                    >
                      +7 (965) 56-78-265
                    </a>
                  </div>
                  <div className="feedback-blockFirst-line"></div>
                  <div className="feedback-blockFirst_img">
                    <Link to="https://vk.com/id38479372">
                      <img
                        className="blockFirst_img-feedback"
                        src="/img/vk.png"
                        alt="vk"
                      />
                    </Link>
                    <Link to="https://t.me/mashiklaz">
                      <img
                        className="blockFirst_img-feedback"
                        src="/img/tg.png"
                        alt="tg"
                      />
                    </Link>
                    <Link className="outline-none" to="/offer">
                      <p className="footer-contractOffer pointer">
                        Договор оферта
                      </p>
                    </Link>
                    <p className="footer-contractOffer">
                      © 2024 Easy Squeezy ИП Лазарева Мария Валерьевна <br />{' '}
                      ИНН: 590773912037 ОГРНИП: 323595800122942
                    </p>
                  </div>
                </div>
                <img
                  onClick={logoToUp}
                  className="hiddenImg"
                  src="img/mobile/logoFooterMobile.png"
                  alt=""
                />
              </div>
            </div>
            <img
              onClick={logoToUp}
              className="content-main_footerLogo"
              src="/img/logoFooter.png"
              alt="logoFooter"
            />
          </div>
          <p className="footer-contractOfferMobile footer-contractOfferMargin">
            {' '}
            © 2024 Easy Squeezy ИП Лазарева Мария Валерьевна <br />
            ИНН: 590773912037 ОГРНИП: 323595800122942
          </p>
        </div>
      </div>
    </body>
  )
}
