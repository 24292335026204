import { Route, Routes } from 'react-router-dom'
import { ContentPage } from './pages/content/ContentPage'
import { ReviewPage } from './pages/review/ReviewPage'
import { PersonalInfo } from './pages/personal/PersonalInfo'

export const AppRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<ContentPage />} />
      <Route path="/review" element={<ReviewPage />} />
      <Route path='/offer' element={<PersonalInfo />} />
    </Routes>
  )
}

